html,
body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: "Inter", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.Editor {
  height: auto;
  outline: 0;
  padding: 1rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  li,
  blockquote,
  pre,
  figure,
  img {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  h1 {
    &:not(:last-child) {
      margin-bottom: 0.75em;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: 2em;
    }
  }

  blockquote {
    padding: 1rem;
    padding-left: 2rem;
    background: #fff;
    border-radius: 0.25rem;
    border: 0.24rem solid #000;
    border-left-width: 1rem;
  }

  pre {
    padding: 1rem;
    background: #000;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 1.25rem;
    line-height: 1.3;
  }
}
