@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .provider-profile-div {
    @apply bg-white py-6 px-6 shadow-provider-proifle rounded-lg;
  }

  .box {
    @apply border border-solid border-black;
  }
}

::-webkit-scrollbar {
  width: 6px;
  scrollbar-width: thin;
  height: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 6px;
  scrollbar-width: thin;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;

  border-radius: 20px;
  width: 6px;
  background-clip: content-box;
  height: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  scrollbar-width: thin;
}

.rmdp-week-day {
  color: #ffffff !important;
  cursor: default !important;
  font-size: 15px !important;
  background-color: #234266 !important;
  width: 80px !important;
  @media only screen and (max-width: 550px) {
    width: 50px !important;
  }
}
.rmdp-day-picker {
  padding: 0px !important;
}
.rmdp-week,
.rmdp-ym {
  display: flex !important;
  justify-content: space-around !important;
  padding: 5px !important;
}
.rmdp-calendar {
  height: max-content !important;
  width: max-content !important;
  padding: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 10px !important;
}
