.schedule-session {
  max-width: 364px;
  /* border: 1px solid #FF6C44; */
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 24px;
  width: 33.333%;
  @media only screen and (max-width: 650px) {
    max-width: 100%;
    width: 100%;
  }
}

.border-color {
  /* border: 1px solid black; */
}

.schedule-session-container {
  /* width: 100%; */
  padding: 16px 32px 32px 32px;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    padding: 8px 16px 32px 16px;
    width: 100%;
  }
}

.schedule-session-select-time-container {
  /* width: 100%; */
  padding: 21px 16px;
  margin: 0px 32px 32px 32px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
    margin: 0px;
    /* padding: 21px 16px; */
  }
}

.schedule-session-calendar-container {
  width: 65%;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
  }
  /* max-width: 689px; */
}

.schedule-session-table-container {
  width: 35%;
  color: #545454;
  flex-direction: column;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }
  /* max-width: 358px; */
}

.schedule-session-select-date-container {
  width: 50%;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
  }
  /* max-width: 689px; */
}

.schedule-session-time-table-container {
  width: 50%;
  color: #545454;
  flex-direction: column;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }
  /* max-width: 358px; */
}

.schedule-table {
  width: 100%;
}

/* .schedule-table thead th:first-child{
	border-radius:10px 0 0 0;
}
  
.schedule-table thead th:last-child{
	border-radius:0 10px 0 0;
}

.schedule-table tbody tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}
.schedule-table tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
} */

th {
  background-color: #66ba83;
  padding: 8px 10px;
}

td {
  border: 1px solid #f5f5f5;
  font-family: "Inter";
}

.MuiDayPicker-header.css-qklzlb-MuiDayPicker-header {
  background-color: #234266;
  justify-content: space-between;
}

.css-a0lqlw-MuiTypography-root-MuiDayPicker-weekDayLabel {
  color: white !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-8hhtkw-MuiButtonBase-root-MuiPickersDay-root {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #545454;
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-dayOutsideMonth.css-ddfgux-MuiButtonBase-root-MuiPickersDay-root {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today.css-neurvu-MuiButtonBase-root-MuiPickersDay-root {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  /* color: #FFFFFF; */
}

/* .css-neurvu-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    border: none;
	background: #FF6C44;
} */

/* button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-8hhtkw-MuiButtonBase-root-MuiPickersDay-root {
	background: #FF6C44;
} */

.MuiPickersCalendarHeader-labelContainer.css-1jgd8m4-MuiPickersCalendarHeader-labelContainer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #333333;
}

/* .css-neurvu-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    border: none;
} */

.MuiDayPicker-weekContainer.css-ghi3gg-MuiDayPicker-weekContainer {
  justify-content: space-between !important;
}

.MuiCalendarPicker-root.css-169iwlq-MuiCalendarPicker-root {
  width: 100%;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.rmdp-week-day {
  color: #ffffff;
  cursor: default;
  font-size: 15px;
  background-color: #234266;
  width: 80px;
  @media only screen and (max-width: 650px) {
    width: 50px;
  }
}
.rmdp-day-picker {
  padding: 0px;
}
.rmdp-week,
.rmdp-ym {
  display: flex;
  justify-content: space-around;
  padding: 5px;
}
.rmdp-calendar {
  height: max-content;
  width: max-content;
  padding: 0px;
  padding-top: 5px;
  padding-bottom: 10px;
}
