.App {
  text-align: left;
  width: 100%;
}
img {
  width: 100%;
}
body {
  font-family: "Inter";
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
ul {
  color: "black";
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.active {
  color: white;
  background-color: #00c657;
}
.inactive {
  color: black;
  background-color: white;
}
button:disabled {
  opacity: 0.5;
}
.default-checkbox:checked {
  background-color: #014f86;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
input:-internal-autofill-selected {
  background-color: transparent;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0px;
}
.loginBoxShadow {
  background: #ffffff;

  box-shadow: 0px 4px 8px -2px rgba(16, 40, 36, 0.1),
    0px 2px 4px -2px rgba(16, 40, 36, 0.06);
}
.searchBoxShadow {
  box-shadow: 0px 1px 3px rgba(16, 40, 36, 0.1),
    0px 1px 2px rgba(16, 40, 36, 0.06);
}

.tableBoxShdow {
  background: #ffffff;

  box-shadow: 0px 4px 8px -2px rgba(16, 40, 36, 0.1),
    0px 2px 4px -2px rgba(16, 40, 36, 0.06);
  border-radius: 8px;
}
.header {
  grid-area: header;
  z-index: 1;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
}
.sidebar {
  grid-area: sidebar;
  z-index: 1;
}
.content {
  grid-area: content;
  z-index: 1;
  margin-top: 100px;
  border-radius: 80px 0px 0px 0px;
}
.g-container {
  overflow-x: hidden;
  height: 100vh;
  grid-template-rows: 70px auto;
  grid-template-columns: 250px auto;
  background: linear-gradient(
    91.53deg,
    #234266 2%,
    #173c68 55.26%,
    #234266 97.07%
  );

  grid-template-areas:
    "header  header "
    "sidebar content ";
}

::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.pac-container {
  z-index: 1500;
}

.bg-banner {
  position: absolute;
  width: 100%;
  height: 180px;
  left: 0px;
  top: 0px;
  z-index: 0;
}

.inner-content {
  background: white;
  width: 100%;
  height: 100%;
  padding: 32px 64px 32px 32px;
}

.appointment-profileImage {
  width: 40px;
  height: 40px;
  border: 1px solid #fffefe;
}

.MuiPickersPopper-root
  css-1anqmj6-MuiPopper-root-MuiPickersPopper-root
  MuiPopper-root {
  top: 90px;
  left: 90px;
  position: absolute;
}
.str-chat__avatar-fallback {
  background: #ff6c44 !important;
}

.schedule-session {
  max-width: 364px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 24px;
  width: 33.333%;
  @media only screen and (max-width: 650px) {
    max-width: 100%;
    width: 100%;
  }
}

.border-color {
}

.schedule-session-container {
  padding: 16px 32px 32px 32px;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    padding: 8px 16px 32px 16px;
    width: 100%;
  }
}

.schedule-session-select-time-container {
  padding: 21px 16px;
  margin: 0px 32px 32px 32px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }
}

.schedule-session-calendar-container {
  width: 65%;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
  }
}

.schedule-session-table-container {
  width: 35%;
  color: #545454;
  flex-direction: column;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }
}

.schedule-session-select-date-container {
  width: 50%;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
  }
}

.schedule-session-time-table-container {
  width: 50%;
  color: #545454;
  flex-direction: column;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }
}

.schedule-table {
  width: 100%;
}

th {
  background-color: #66ba83;
  padding: 8px 10px;
}

td {
  border: 1px solid #f5f5f5;
  font-family: "Inter";
}

.MuiDayPicker-header.css-qklzlb-MuiDayPicker-header {
  background-color: #234266;
  justify-content: space-between;
}

.css-a0lqlw-MuiTypography-root-MuiDayPicker-weekDayLabel {
  color: white !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-8hhtkw-MuiButtonBase-root-MuiPickersDay-root {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #545454;
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-dayOutsideMonth.css-ddfgux-MuiButtonBase-root-MuiPickersDay-root {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today.css-neurvu-MuiButtonBase-root-MuiPickersDay-root {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.MuiPickersCalendarHeader-labelContainer.css-1jgd8m4-MuiPickersCalendarHeader-labelContainer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #333333;
}


.MuiDayPicker-weekContainer.css-ghi3gg-MuiDayPicker-weekContainer {
  justify-content: space-between !important;
}

.MuiCalendarPicker-root.css-169iwlq-MuiCalendarPicker-root {
  width: 100%;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

