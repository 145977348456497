.appointments-table {
  table {
    background-color: #739e91;
    color: white;
    border-radius: 4px;
    width: 100%;
    overflow: hidden;

    th,
    td {
      padding: 0.75rem 0.5rem;
      border: 1px solid #7070701a;
    }

    td {
      background: #e8f7f2;
      color: #264265;
      text-align: center;
    }
  }
}

.checkins-table {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    border-right: 1px solid #d1e2dc;
    border-bottom: 1px solid #d1e2dc;
  }
  th {
    margin-bottom: 1rem;
    text-align: center;

    &:first-child {
      width: 150px;
    }
    &:nth-child(2) {
      width: 150px;
    }
  }
  td {
    background: #e8f7f2;
    color: #597599;
    &:last-child {
      border-right: none;
    }
    &:nth-child(2) {
      width: 150px;
    }
  }

  tr {
    border-radius: 5px !important;
    /* &:nth-child(4n) {
      border-bottom: 10px solid white;
    } */
  }
  .lg-col-td {
    width: 150px;
    font: normal normal 500 14px/21px Poppins;
    color: #264265;
  }
}

.gad7-table {
  .gad7-table-header {
    p {
      font-size: 0.875rem;
    }
  }
}
.str-chat__header-livestream {
  box-shadow: none !important;
  background: #fff !important;
  border-radius: 0 !important;
  min-height: 60px !important;
  border-bottom: 1px solid #f3f3f3 !important;
}
.str-chat__input-flat .str-chat__textarea > textarea {
  background: #e6eff2 0% 0% no-repeat padding-box !important;
}
.str-chat__message--me .str-chat__message-text-inner,
.str-chat__message-simple--me .str-chat__message-text-inner {
  min-width: 60px;
  padding: 8px 20px;
  font-size: 14px;
  background: #e6eff2 0% 0% no-repeat padding-box !important;
  border-radius: 11px !important;
  color: rgba(38, 66, 101, 1);
}
.str-chat__li--bottom .str-chat__message--me .str-chat__message-text-inner,
.str-chat__li--middle .str-chat__message--me .str-chat__message-text-inner > p {
  font-size: 14px !important;
  padding: 8px 20px;
  color: rgba(38, 66, 101, 1);
}

.str-chat.messaging,
.str-chat.commerce {
  height: 530px !important;
}
.messaging.str-chat .str-chat__list:-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.messaging.str-chat .str-chat__list::-webkit-scrollbar-thumb {
  background: #ef5e04;
}
.str-chat__input-flat-emojiselect {
  top: calc(100% - 48px) !important;
}
