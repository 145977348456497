.css-14pt78w-MuiSlider-rail {
  display: block;
  position: absolute;
  border-radius: inherit;
  background-color: #ff6c44;
  opacity: 1;
  width: 100%;
  height: inherit;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.css-18b4tu0-MuiSlider-root {
  border-radius: 12px;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  color: #6fcf97;
  -webkit-tap-highlight-color: transparent;
  height: 6px;
  width: 100%;
  padding: 13px 0;
  margin-bottom: 20px;
}

.css-6cwnna-MuiSlider-mark {
  position: absolute;
  width: 1px;
  height: 7px;
  border-radius: 1px;
  background-color: #757575;
  top: 72%;
  -webkit-transform: translate(-1px, -50%);
  -moz-transform: translate(-1px, -50%);
  -ms-transform: translate(-1px, -50%);
  transform: translate(-1px, -50%);
  opacity: 0.8;
}
.css-1tfve6q-MuiSlider-mark {
    position: absolute;
    width: 1px;
    height: 7px;
    border-radius: 1px;
    background-color: #757575;
    top: 72%;
    -webkit-transform: translate(-1px, -50%);
    -moz-transform: translate(-1px, -50%);
    -ms-transform: translate(-1px, -50%);
    transform: translate(-1px, -50%);
}

.css-cd0lep-MuiSlider-markLabel {
    /* font-family: "Poppins",sans-serif -webkit-font-smoothing:antialiased -moz-osx-font-smoothing:grayscale!important; */
    font-weight: 400;
    font-size: 12px;
    line-height: 1.43;
    color: rgb(255,108,68);
    position: absolute;
    white-space: nowrap;
    top: 30px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.css-1k24fgw-MuiSlider-markLabel {
    /* font-family: "Poppins",sans-serif -webkit-font-smoothing:antialiased -moz-osx-font-smoothing:grayscale!important; */
    font-weight: 400;
    font-size: 12px;
    line-height: 1.43;
    color: rgb(255,108,68);
    position: absolute;
    white-space: nowrap;
    top: 30px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}